import React, { useEffect, useState, useRef } from 'react';
import Rating from 'react-rating';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isMobileOnly, isMobile } from 'react-device-detect';
import _ from 'lodash';

import Button from '../../../components/Button';
import AudioPlayer from '../../../components/CustomAudioPlayer';
import Banner from '../../initialAssessment/Banner';
import {
  getFeedbackAll,
  deleteFinalRecord,
  clearPostLesson,
  getModuleDetailService,
} from '../../../../services/moduleServices';
import { selectSelectedModule } from '../../../../actions/module/moduleSlice';
import { getData } from '../../../../util/localStorageHandler.js';
import Segment from '../../../../segment';
import styles from './Feedback.module.css';
import '../../../../../../node_modules/slick-carousel/slick/slick.css';
import '../../../../../../node_modules/slick-carousel/slick/slick-theme.css';

const Feedback = (props) => {
  let history = useHistory();
  const userDataRef = useRef();
  const selectedModule = useSelector(selectSelectedModule);
  const [feedbackData, setFeedbackData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [moduleDetailsData, setModuleDetailsData] = useState({});
  const indexRef = useRef(1);
  const audiosRef = {};

  useEffect(() => {
    if (!selectedModule) {
      history.push('/modules');
      return;
    }
    if (selectedModule.intro_module) {
      Segment.track('IntroCoachingPVisit');
      getModuleDetailService(selectedModule.id).then((res) => {
        if (!res.isError && !res.errorCode) {
          setFeedbackData({
            description:
              'Please listen to the audio recording to hear a sample of a ChatterFox coach giving a user feedback. This will give you an idea of the coaching you will get from your own coach!',
            userdata: {
              scores: [],
              userdata: {
                prelessonRecords: [],
                finalRecords: [
                  {
                    recording_path: res.module.final_assignment[0].assignment_file,
                    postlesson_feedbacks: [
                      {
                        coach_photo: process.env.PUBLIC_URL + '/images/coachAvatar.jpg',
                        coach_name: 'Coach Clara',
                        audio_url: process.env.PUBLIC_URL + '/sounds/Sample Coaching.mp3',
                      },
                    ],
                  },
                ],
              },
            },
          });
          setModuleDetailsData(selectedModule);
        } else {
          history.push('/modules');
        }
      });
      return;
    }
    userDataRef.current = getData();
    const dataToSend = {
      module_id: props.moduleId,
    };
    getFeedbackAll(userDataRef.current.api_token, dataToSend).then((res) => {
      setFeedbackData(res.data);
      setModuleDetailsData(res.data.module);
    });
  }, []);

  const stopOtherAudioPlayers = (currentPlayerId) => {
    if (selectedModule.intro_module && !currentPlayerId.includes('feedback')) {
      Segment.track('SampleCoachingPlay');
    }
    Object.keys(audiosRef).forEach((value) => {
      if (value !== currentPlayerId && audiosRef[value].current) audiosRef[value].current.stop();
    });
  };

  const handlePracticeAgain = () => {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure to remove your previous feedback.',
      overlayClassName: `${isMobile && 'mobile'}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setLoading(true);
            clearPostLesson(props.moduleId)
              .then((res) => {
                if (res && !res.isError && !res.errorCode) {
                  // const userDataFromLocalStorage = getData();
                  // props.getModuleDetails(userDataFromLocalStorage.api_token, props.moduleId);
                  history.push(`/modules/${props.moduleId}`);
                  setLoading(false);
                } else {
                  confirmAlert({
                    title: '',
                    message: 'Something went wrong, please try again later',
                    overlayClassName: `${isMobile && 'mobile'}`,
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {},
                      },
                    ],
                  });
                  setLoading(false);
                }
              })
              .catch((error) => {
                setLoading(false);
                confirmAlert({
                  title: '',
                  message: error.message,
                  overlayClassName: `${isMobile && 'mobile'}`,
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => {},
                    },
                  ],
                });
              });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    confirmAlert({
      title: 'Confirm to cancel',
      message: 'Are you sure to do this.',
      overlayClassName: `${isMobile && 'mobile'}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteFinalRecordData(id),
        },
        {
          label: 'No',
          onClick: () => console.log('hello'),
        },
      ],
    });
  };

  const deleteFinalRecordData = (id) => {
    let dataToSend = {
      module_id: props.moduleId,
      assessment_id: id,
    };
    deleteFinalRecord(userDataRef.current.api_token, dataToSend).then((res) => {
      //console.log("delete record response...",res);
      if (res.isError != 1) {
        //window.location.reload();
      }
    });
  };

  const LessonRecordWithFeedback = ({ record, index }) => {
    const [show, setShow] = useState(true);
    const hasFeedback = _.get(record, 'postlesson_feedbacks', []).length !== 0;
    const playerId = `post_${index}`;
    audiosRef[playerId] = useRef();

    const PostLessonFeedback = ({ feedback, index }) => {
      const playerId2 = `feedback_${index}`;
      audiosRef[playerId2] = useRef();

      return (
        <>
          <img className={styles.avatar} src={feedback.coach_photo} />
          <div className={styles.audioHolder}>
            <div className={styles.coachingDetails}>
              <span className={styles.coachName}>{feedback.coach_name}</span>
              <span className={styles.coachingDate}>{feedback.created_at}</span>
            </div>
            <AudioPlayer
              key={index}
              ref={audiosRef[playerId2]}
              id={playerId2}
              className={styles.audioPlayer}
              source={feedback.audio_url}
              pauseable={false}
              onStart={() => stopOtherAudioPlayers(playerId2)}
            />
          </div>
        </>
      );
    };

    PostLessonFeedback.propTypes = {
      feedback: PropTypes.object,
    };

    return (
      record.recording_path && (
        <div key={index} className={styles.record}>
          <div
            className={`${styles.box} ${hasFeedback && styles._hover} _show`}
            onClick={(e) => {
              if (hasFeedback) {
                e.currentTarget.classList.toggle('_show');
                // setShow(!show);
              }
            }}
          >
            <AudioPlayer
              ref={audiosRef[playerId]}
              id={playerId}
              className={styles.audioPlayer}
              source={record.recording_path}
              hideBar={true}
              label={record.post_lesson_title}
              showDuration={true}
              pauseable={false}
              onStart={() => stopOtherAudioPlayers(playerId)}
            />
            {hasFeedback && (
              <div className={styles.feedbackIcon}>
                <img src={process.env.PUBLIC_URL + '/images/coachFeedback.svg'} />
              </div>
            )}
          </div>
          {hasFeedback && (
            <Collapse in={show}>
              <div className={styles.feedbackList}>
                {/* Need to collapse work properly */}
                <div className={`${styles.feedback} ${styles.chatMode}`}>
                  {record.postlesson_feedbacks.map((feedback, indx) => (
                    <PostLessonFeedback key={indx} feedback={feedback} index={index + indx} />
                  ))}
                </div>
              </div>
            </Collapse>
          )}
        </div>
      )
    );
  };

  LessonRecordWithFeedback.propTypes = {
    record: PropTypes.any,
    index: PropTypes.string,
  };

  indexRef.current = 1;

  return (
    <div className={styles.container}>
      {feedbackData ? (
        <>
          <div className={styles.note}>{feedbackData.description}</div>
          {feedbackData.userdata.userdata.prelessonRecords.length > 0 && (
            <div className={styles.section}>
              <div className={styles.header}>
                <span className={styles.headerBullet}>{indexRef.current++}</span>
                <span className={styles.headerText}>Pre-Lesson</span>
              </div>
              <div className={styles.content}>
                {feedbackData.userdata.userdata.prelessonRecords.map((record, index) => (
                  <LessonRecordWithFeedback key={index} record={record} index={`pre_${index}`} />
                ))}
              </div>
            </div>
          )}

          {feedbackData.userdata.userdata.finalRecords.length > 0 && (
            <div className={styles.section}>
              <div className={styles.header}>
                <span className={styles.headerBullet}>{indexRef.current++}</span>
                <span className={styles.headerText}>Post-Lesson</span>
              </div>
              <div className={styles.content}>
                {feedbackData.userdata.userdata.finalRecords.map((record, index) => (
                  <LessonRecordWithFeedback key={index} record={record} index={`post_${index}`} />
                ))}
              </div>
            </div>
          )}

          {feedbackData.userdata.scores.length > 0 && (
            <div className={`${styles.section} ${styles.overview}`}>
              <div className={styles.header}>
                <span className={styles.headerBullet}>{indexRef.current++}</span>
                <span className={styles.headerText}>Overview</span>
              </div>
              <div className={styles.content}>
                <div className={styles.scoreList}>
                  {feedbackData.userdata.scores.map((score, index) => {
                    return (
                      <div key={index} className={styles.score}>
                        <span>{score.factor_name}</span>
                        <Rating
                          className={styles.rating}
                          initialRating={parseFloat(score.score)}
                          readonly
                          emptySymbol={<img src={process.env.PUBLIC_URL + '/images/icon/empty-star.svg'} />}
                          fullSymbol={<img src={process.env.PUBLIC_URL + '/images/icon/star.svg'} />}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {selectedModule.intro_module ? (
            <Banner />
          ) : (
            <div className={styles.practiceAgain}>
              <div className={styles.textBox}>
                <span>
                  To try again and get more feedback, please click the &apos;practice again&apos; button. Please note
                  this will remove your previous feedback.
                </span>
              </div>
              {/* <Button
                className={styles.btn}
                type="warning"
                content="Practice Again"
                onClick={handlePracticeAgain}
                isLoading={loading}
              /> */}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

Feedback.propTypes = {
  moduleId: PropTypes.string,
  moduleTitle: PropTypes.string,
  getModuleDetails: PropTypes.func,
};

export default Feedback;
